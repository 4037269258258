import React from "react"
import Layout from "@components/kaigai-fx/layout";
import 'tw-elements';

// 関数呼び出し
import { TermComponent } from "@components/kaigai-fx/term-component.js";

const TermPageS = () => {
  // 設定項目 ================================================================================================
  const myLine = 'さ行'
  const myID = 'sa_'
  const pathName = 'term-sa';
  const termItem = [ // 改行するときは<br>を追加
  {
    Title:'差金決済',
    Content:'現物の受け渡しを行わないで、売却金額と買付金額の差額のみの受け渡しをする取引のことです。<br>FXも差金決済を使った取引です。'
  },
  {
    Title:'指値注文',
    Content:'希望する売買価格を自分で指定する注文方法です。買いの場合は上限価格、売りの場合は下限価格を設定します。'
  },
  {
    Title:'実効レバレッジ',
    Content:'預託金（口座精算価値）に対して現在のポジションが何倍に達しているのかを示した数値です。'
  },
  {
    Title:'出金依頼額',
    Content:'出金依頼中の金額のことです。'
  },
  {
    Title:'証拠金',
    Content:'取引の契約義務の履行を確保するために差し入れる金銭のことをいいます。'
  },
  {
    Title:'新規注文可能額',
    Content:'新たに買建注文または売建注文可能な金額のことです。'
  },
  {
    Title:'スプレッド',
    Content:'通貨ペアを売る際に適用される「売値（Bid）」と、買う際に適用される「買値（Ask）」の差額がスプレッドとなります。<br>スプレッドが小さいほどコストをおさえることができ利益を出せる可能性が高くなります。'
  },
  {
    Title:'スリッページ',
    Content:'お客様の注文レートと実際に約定したレートの差のことです。<br>為替相場の変動等によりお客様が注文時に指定したレートと実際に約定するレートとの間にかい離が生じる場合があり、<br>注文レートと異なるレートで約定する場合があります。'
  },
  {
    Title:'スワップポイント',
    Content:'スワップポイントは外貨ごとに金利が違うことで発生する、通貨間の金利差調整額のことです。<br>FXは、任意の通貨ペアの売買を行うことで為替差益を狙う取引方法ですが、<br>スワップポイントは「売った外貨の利子を支払う」、「買った外貨の利子を受け取る」のように<br>利子の差分を受け払いすることを利用して、為替差益とは別で利益を狙うことができます。'
  },
  {
    Title:'損切り',
    Content:'損失を抱えているポジションを決済し、損失を確定させることをいいます。<br>損切りが早ければ損失を小さくすることができます。'
  }
  ]
  // =================================================================================================================================
  // term-component.jsにて詳細設定
  let termPageContent = TermComponent( myLine, myID, termItem, pathName ); 
  
  return (
  <>
    <Layout>
      {termPageContent}
    </Layout>
  </>
  )
}
export default TermPageS